.contador
{
    margin-top: 5%;
}
.gg 
{
display: flex;
width: 45%;
}

.gg p {
    font-size: 12px;
}

.kk span
{
    font-size: 300%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}
.kk 
{
    margin-top: 10%;

}




@media screen and (max-width:600px) {
    .contador
{
    margin-top: 5%;
}
.gg 
{
display: flex;
width: 45%;
}

.gg p {
    font-size: 12px;
}

.kk span
{
    font-size: 300%;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
}
.kk 
{
    margin-top: 10%;

}
}


@media screen and (max-width:500px) {
    .kk span
    {
        font-size: 200%;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: bold;
    }
}
