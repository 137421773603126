.contenedor {
  width: 279px;
  background: #fff;
  text-align: center;
  /*box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;*/
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  margin-bottom: 1%;
}

.tituloDiv {
  width: 100%;
  height: 41px;
  background: #000;
  color: #fff;
  text-align: center;
  margin-top: -8px;
  padding-top: 15px;
  font-family: 'Anton', sans-serif; 
  font-size: 36px;
  /* text-transform: uppercase; */
}

.texto {
    font-size: 15px;
    line-height: 20px;
    padding: 17px;
}


@media screen and (max-width:600px) {
  
  .contenedor {
    width: 100%;
    margin-bottom: 5%;
    padding-bottom: 5%;
  }

  .contenedor img {
    width:100%;
  }
  .texto {
    padding-top: 8%;
    font-size: 18px;
    line-height: 150%;
   }

  .tituloDiv {
    padding-top:7%;
    padding-bottom:4%;
  }

}