@import url('https://fonts.googleapis.com/css2?family=Anton&family=Inter:wght@200;300&display=swap');

.boton {
    width: 100%;
    background: #70B630;
    border: none;
    border-radius: 50px;
    font-family: 'Anton', sans-serif;
    font-size: 140%;
    color: #fff;
    text-align: center;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(176,176,176,0.61);
    -moz-box-shadow: 0px 0px 9px 0px rgba(176,176,176,0.61);
    box-shadow: 0px 0px 9px 0px rgba(176,176,176,0.61);

    transition: all ease-in 0.2s;
    padding:20px;
    display: flex;
  }

  .boton:hover {
      background: #509C0A;
      transition: all ease-in 0.2s;
  }

  .boton  p{
    font-family: 'Anton', sans-serif;
    font-size: 100%;
    color: #fff;
    margin-top: 1px;
  }
  .sdsd {
    width: 9%;
  }

  @media screen and (max-width:400px) {
  
    .boton {
      width: 100%;
      background: #70B630;
      border: none;
      border-radius: 50px;
      font-family: 'Anton', sans-serif;
      font-size: 100%;
      color: #fff;
      text-align: center;
      cursor: pointer;
      -webkit-box-shadow: 0px 0px 9px 0px rgba(176,176,176,0.61);
      -moz-box-shadow: 0px 0px 9px 0px rgba(176,176,176,0.61);
      box-shadow: 0px 0px 9px 0px rgba(176,176,176,0.61);
  
      transition: all ease-in 0.2s;
      padding:15px;
      display: flex;
    }
  
    .boton  p{
      font-family: 'Anton', sans-serif;
      font-size: 100%;
      color: #fff;
      margin-top: 1px;
    }
    .sdsd {
      width: 10%;
    }

  }