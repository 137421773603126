.contenedor {
    background: #312C2C;
    color: #998787;
    padding-top: 2%;
    padding-bottom: 5%;
}
.contenedor p {
    font-size: 16px;
    line-height: 150%;
}



@media screen and (max-width:600px) {
    .contenedor {
        padding-left: 5%;
        padding-right: 5%;
        grid-column: 1/-1;
        padding-top: 15%;
        padding-bottom: 15%;
    }
    .contenedor p {
        font-size: 16px;
        line-height: 150%;
    }
  }