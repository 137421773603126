.contenedor {
  text-align: center;
}

h1{
  font-family: 'Anton', sans-serif;
  font-size: 160%;
  color: #000;
  line-height: 120%;
  text-align: center;
  margin-bottom: 4%;
  margin-top: 3%;
}
.p {
    text-align: center;
    font-size: 20px;
    margin-top: 5%;
    width: 90%;
}
.pie{
    font-size: 12px;
    line-height: 150%;
    width: 70%;
    margin-bottom: 2%;
    margin-top: 5%;
}



@media screen and (max-width:600px) {

  .contenedor p {
    font-size: 80%;
    line-height:130%;
  }

  .contenedor .pie {
    font-size: 40%;
  }

  h1{
    margin-top: 15%;
  }

  .pie{
    line-height: 150%;
    width: 70%;
    padding-bottom: 10%;
  }
 .contenedor .pie {
    font-size: 50%;
  }

}


@media screen and (max-width:420px) {

  .contenedor p {
    font-size: 80%;
    line-height: 130%;
  }

  .pie{
    line-height: 150%;
    width: 90%;padding-bottom: 10%;
  }
 .contenedor .pie {
  font-size: 50%;
  }
}
 
@media screen and (max-width:414px) {

  .contenedor p {
    font-size: 80%;
    line-height:130%;
  }

  .contenedor .pie {
  font-size: 40%;
  }

  h1{
    margin-top: 15%;
  }

  .pie{
    line-height: 150%;
    width: 90%;padding-bottom: 10%;
  }
 .contenedor .pie {
  font-size: 50%;
  }

}


@media screen and (max-width:360px) {
  h1{
    margin-bottom: 4%;
    margin-top: 12%;
    font-size: 30px;
  }

  .contenedor p {
    font-size: 80%;
    line-height: 130%;
  }

  .contenedor .pie {
  font-size: 40%;
  }

  .pie{
    line-height: 150%;
    width: 90%;padding-bottom: 10%;
  }
 .contenedor .pie {
  font-size: 50%;
  }
 
}



@media screen and (max-width:320px) {
  h1{
    margin-bottom: 4%;
    margin-top: 12%;
    font-size: 24px;
  }

  .contenedor p {
    font-size: 80%;
    line-height: 130%;
  }

  .pie{
    line-height: 150%;
    width: 90%;padding-bottom: 10%;
  }
 .contenedor .pie {
  font-size: 50%;
  }

}


@media screen and (max-width:300px) {

  h1{
    margin-bottom: 4%;
    margin-top: 12%;
    font-size: 24px;
  }

  .contenedor p {
    font-size: 80%;
    line-height: 130%;
  }

  .pie{
    line-height: 150%;
    width: 90%;padding-bottom: 10%;
  }
 .contenedor .pie {
  font-size: 50%;
  }

}

