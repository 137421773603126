@import url('https://fonts.googleapis.com/css2?family=Anton&family=Inter:wght@200;300&display=swap');

.textosBanner {
    grid-column: 1/8;
    grid-row: 1/-1;
    margin-top: 74px;
}
.tituloPrincipal {
    font-family: 'Anton', sans-serif;
    font-size: 64px;
    line-height: 70px;
}
.bote {
    grid-column: 7/12;
    grid-row: 1/-1;
    margin-right: -45px;
    margin-top: 60px;
}
.parrafo {
    font-size: 24px;
    margin-top: 20px;
    margin-left: 0px;
    width: 700px;
}
.sellos {
    margin-left: -20px;
    margin-top: 5px;
}



@media screen and (max-width:600px) {

    .bote {
        grid-column: 1/-1;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .textosBanner {
        grid-column: 1/-1;
        width: 90%;
        margin-top: 85%;
        text-align: left;
        padding: 2%;
    }

    .tituloPrincipal {
        font-size: 300%;
        line-height: 60px;
        font-family: 'Anton', sans-serif;
    }
    .parrafo {
        font-size: 20px;
        margin-top: 15px;
        width: 100%;
        line-height: 30px;
        grid-column: 1/-1;
    }
    .sellos {
        grid-column: 1/-1;
        margin: auto;
        width: 100%;
        margin-top: 20px;
        margin-left: -10px;
    }
}