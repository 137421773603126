.contenedor {
    max-height:7%;
    width: 100%;
    background: #1B1818;
    height: 20%;
    display: flex;
    position: fixed;
    padding-bottom: 35%;
}

.contenedorNone {
    width: 100%;
    background: #000;
    display: none;
}

.mm {
    color: #fff;
    list-style: none;
    margin-left: 7%;
    margin-top: 8%;
    cursor: pointer;
}

.mm li{
    color: #fff;
    list-style: none;
    margin-bottom: 10%;
    text-align: left;
}
.contenedorbtn {
    width: 140%;
    margin-left: -15%;
}




@media screen and (max-width:600px) {
    .contenedorbtn {
        width: 180%;
        margin-left: -20%;
    }
}
@media screen and (max-width:460px) {
    .contenedorbtn {
        width: 140%;
        margin-left: -15%;
    }
}
@media screen and (max-width:420px) {}
@media screen and (max-width:360px) {}
@media screen and (max-width:320px) {}
@media screen and (max-width:300px) {}