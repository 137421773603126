@import url('https://fonts.googleapis.com/css2?family=Anton&family=Inter:wght@200;300&display=swap');


.main {
    width:90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
}


.btn {
    width: 100%;
    background: #70B630;
    border: none;
    border-radius: 50px;
    font-size: 100%;
    color: #fff;
    text-align: center;
    cursor: pointer;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(176,176,176,0.61);
    -moz-box-shadow: 0px 0px 9px 0px rgba(176,176,176,0.61);
    box-shadow: 0px 0px 9px 0px rgba(176,176,176,0.61);
    transition: all ease-in 0.2s;
    display: flex;
    margin-top:-2%;
    letter-spacing: 1px; 
    padding: 3.5% 5% 3.5% 5%;
}

.btn:hover{
      background: #509C0A;
      transition: all ease-in 0.2s;
  }

.btn  p{
    font-family: 'Anton', sans-serif;
    font-size: 100%;
    color: #fff;
    margin-top: 1px;
  }


.in 
input
{
    width: 90%;
    background: #ffff;
    border-radius: 5px;
    padding: 3%;
    font-size: 16px;
    outline: none;
    border: solid 1px #DDD7D7;
    color: #7C7474;
}
 

.in span
{
    margin-top: -9%;
    float: left;
    transition: 0.2s;
    color: #C3C1C1;
    font-size: 80%;
    position: absolute;
    margin-left: 2%;
}


.main .in
{
    margin-top:-7%;
}


.opcionesPaises {
    width: 101%;
    float: left;
    margin-left: -1%;
    background-color: #fff;
    margin-bottom: 5%;
    margin-top: 3%;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    position: absolute;
}

.opcionesPaisesNone {
    display: none;
}


.banderas {
    width: 100%;
    height: 60px;
    background: #Fff;
    font-size: 16px;
    display: flex;
}

.paises {
    margin-left: 4%;
    margin-top: 3.8%;
    display: flex;
    cursor: pointer;
}

.pik {
    margin-left: 10%;
}

.plus {
    margin-left: 5%;
    font-size: 85%;
}

.in, .inw 
{
    position: relative;
    width: 100%;
    margin-top: 5%;
    margin-bottom: 10%;
}

.inw 
{
    margin-left: 6%;
    margin-top: -5%;
}

.inw input
{
    padding: 5%;
    width: 84%;
    margin-top: 6%;
    color: #7C7474;
}


.inw span {
    margin-top: -7%;
}


.option {
    display: flex;
    padding-top: 0%;
    padding-bottom: 0%;
    background: #fff;
    border-bottom: solid 1px #F8F1F1;
}

.option:hover {
   background: #F8F1F1;
   cursor: pointer;
}

.nombreBandra {
    font-size: 16px;
    float: right;
    margin-right: 100%;
    margin-left: 12%;
}

.aa {
    width: 30px;
    float: right;
    display: flex;
    padding: 3%;
    margin-left: 2%;
}

.plusB{
  font-size: 70%;
  margin-left: 40%;
  width: 200px;
}

.nn, .Ban{
    width: 30px;
}





@media screen and (max-width:600px) {
    .main {
        width:70%;
        margin-top: 15%;
        margin-left: auto;
        margin-right: auto;
    }
}


@media screen and (max-width:460px) {
    
    .main {
        width:100%;
        margin-top: 12%;
        margin-left: auto;
        margin-right: auto;
    }

    .in 
    input
    {
        width: 94%;
        background: #ffff;
        border-radius: 5px;
        padding: 3%;
        font-size: 16px;
        outline: none;
        border: solid 1px #DDD7D7;
        color: #7C7474;
    }
    

    .btn {
        margin-top: -2%;
    }

    .in .inw input {
       padding: 5%;
       width: 90%;
       margin-top: 7%;
    }


}



@media screen and (max-width:420px) {
    .main {
        width:100%;
        margin-top: 12%;
        margin-left: auto;
        margin-right: auto;
    }

    .in 
    input
    {
        width: 94%;
        background: #ffff;
        border-radius: 5px;
        padding: 3%;
        font-size: 16px;
        outline: none;
        border: solid 1px #DDD7D7;
        color: #7C7474;
    }

    .btn {
        margin-top: -2%;
    }

    .in .inw input {
       padding: 5%;
       width: 90%;
       margin-top: 7%;
    }

}


@media screen and (max-width:360px) {

   .main {
        width:100%;
        margin-top: 15%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .pik {
        margin-left: -77%;
    }
    
    .plus {
        margin-left: -76%;
        font-size: 85%;
    }

    .nn {
        width: 9%;
        margin-left: -2%;
    }

    .inw {
        width: 69%;
        display: flex;
        margin-left: 29%;
        background: #fff;
        position: absolute;
        margin-top: -3%;
    }
    
    .inw input {
        width: 100%;
        display: flex;
        background: #fff;
        position: relative;
    }

    .paises {
        margin-top: 5%;
    }

    .opcionesPaises {
        width: 99%;
    }
    
    .btn {
        margin-top: -2%;
    }

    .in .inw input {
       padding: 5%;
       width: 90%;
       margin-top: 7%;
    }


}




@media screen and (max-width:320px) {
    .main {
        width:100%;
        margin-top: 15%;
        margin-left: auto;
        margin-right: auto;
    }
   
    .btn {
        margin-top: -2%;
    }

    .in .inw input {
       padding: 5%;
       width: 90%;
       margin-top: 7%;
    }

}


@media screen and (max-width:300px) {
    .main {
        width:100%;
        margin-top: 15%;
        margin-left: auto;
        margin-right: auto;
    }
    
    .btn {
        margin-top: -2%;
    }

    .in .inw input {
       padding: 5%;
       width: 90%;
       margin-top: 7%;
    }

}