.contenedor {
    width: 100%;
    position: fixed;
    bottom: 0;
    padding-top: 6%;
    padding-bottom: 3%;
    display: none;
}



@media screen and (max-width:600px) {

  .contenedor {
    display: flex;
    width: 100%;
   }

  .container {
    width: 100%;
    height: 5%;
    display: flex;
    background: #fff;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }

  .containerNone {
    display: none;
  }

  .contenedor {
        display: flex;
        width: 100%;
    }

}


